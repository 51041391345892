import filterPresetsApi from '@/utils/api-v2/filterPresets';
import { FILTERS_V2 } from '@/constants/filtersModelNames';
import { TODAY_STRING } from '@/helpers/dates';

export default {
    namespaced: true,
    state: {
        presets: [],
        visiblePresets: [],
        activePreset: null,
        presetToEdit: null
    },
    mutations: {
        SET_PRESETS (state, payload) {
            state.presets = payload;
        },
        DELETE_PRESET (state, id) {
            state.presets = state.presets.filter(preset => preset.id !== id);
        },
        SET_ACTIVE_PRESET (state, payload) {
            state.activePreset = payload;
        },
        SHOW_PRESET (state, id) {
            state.visiblePresets.push(id);
        },
        HIDE_PRESET (state, id) {
            state.visiblePresets = state.visiblePresets.filter(preset => preset !== id);
        },
        SET_VISIBLE_PRESETS (state, payload) {
            state.visiblePresets = payload;
        }
    },
    actions: {
        fetchPresets ({ commit }) {
            return filterPresetsApi.getFilterPresets().then(response => {
                commit('SET_PRESETS', response.data.data);
            });
        },
        deletePreset ({ dispatch, commit, state }, id) {
            return filterPresetsApi.deleteFilterPreset(id).then(() => {
                if (state.activePreset?.key === id) commit('SET_ACTIVE_PRESET', null);
                commit('DELETE_PRESET', id);
                commit('HIDE_PRESET', id);
            });
        },
        addPreset ({ dispatch }, payload) {
            return filterPresetsApi.createFilterPreset(payload).then(() => {
                dispatch('fetchPresets');
            });
        },
        updatePreset ({ dispatch, commit, state }, payload) {
            return filterPresetsApi.updateFilterPreset(payload.id, payload).then(() => {
                if (state.activePreset?.key === payload.id) commit('SET_ACTIVE_PRESET', null);
                dispatch('fetchPresets');
            });
        },
        hidePreset ({ state, commit }, id) {
            commit('HIDE_PRESET', id);
            if (state.activePreset?.key === id) commit('SET_ACTIVE_PRESET', null);
        },
        toggleActive ({ dispatch, commit, state }, payload) {
            if (state.activePreset?.key !== payload.key) {
                dispatch('activatePreset', payload);
            } else {
                commit('SET_ACTIVE_PRESET', null);
            }
        },
        activatePreset ({ commit, rootState }, payload) {
            commit('SET_ACTIVE_PRESET', payload);
            commit('filters/SET_FILTERS', {
                ...rootState.filters.filters,
                [FILTERS_V2.START_DATE]: TODAY_STRING,
                [FILTERS_V2.END_DATE]: TODAY_STRING
            }, { root: true });
        },
        clearPresets ({ commit }, withPresetClearer = false) {
            if (withPresetClearer) commit('SET_PRESETS', []);
            commit('SET_VISIBLE_PRESETS', []);
            commit('SET_ACTIVE_PRESET', null);
        }
    }
};
