<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-layout align-center
                  justify-space-between
                  class="mb-8">
            <v-flex>
                <a class="mr-3"
                   @click="emitFetch">
                    <v-icon size="20">{{ ICONS.REFRESH }}</v-icon>
                    {{ $t('buttons.refresh_list') }}
                </a>
                <GetFile :title="$t('buttons.export_xls')"
                         :loader="xlsLoader"
                         data-test="getXls"
                         @get-file="emitGetFile">
                    <template slot="icon">
                        <v-icon class="mr-1"
                                size="20">{{ ICONS.EXPORT }}
                        </v-icon>
                    </template>
                </GetFile>
            </v-flex>
        </v-layout>

        <Pagination :pagination="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>

        <v-data-table
            :headers="getHeaders()"
            :hide-default-header="$vuetify.breakpoint.smAndUp"
            disable-sort
            :loading="loader"
            :items="inspections"
            :item-class="additionalClass"
            disable-pagination
            hide-default-footer
            show-select
            item-key="id"
            @dblclick:row="showRowDetails"
            v-model="selected"
            height="60vh"
            fixed-header
            class="elevation-1">
            <template v-slot:header="{ props, on }"
                      v-if="$vuetify.breakpoint.smAndUp">
                <thead>
                <tr>
                    <th v-for="(headerItem, index) in props.headers"
                        :key="index">
                        <v-simple-checkbox
                            :ripple="false"
                            @input="on['toggle-select-all']"
                            v-if="headerItem.value === 'data-table-select'"
                            v-model="props.everyItem"
                            :indeterminate="props.someItems && !props.everyItem"
                            color="purple"
                        ></v-simple-checkbox>
                        <SortableHeader v-else :header-item="headerItem"
                                        @sort="sort"></SortableHeader>
                    </th>
                </tr>
                </thead>
            </template>

            <template v-slot:item.id="{ item }">
                <span class="clickable"
                      @click="showDetails(item.id)">{{ item.id }}</span>
            </template>

            <template v-slot:item.parsed_local_end_date_time="{ item }">
                {{ getInspectionDate(item) }}
            </template>

            <template v-slot:item.template_name="{ item }">
                <span v-if="item.template_color" class="color-example" :style="getCssVars(item.template_color)"></span>
                {{ item.template_name }}
            </template>

            <template v-slot:item.state_mark="{ item }">
                <HintingIcon :color="stateIconColor(item)"
                             v-if="stateIcon(item)"
                             :icon="stateIcon(item)"
                             :tooltip="stateTooltip(item)"></HintingIcon>
            </template>

            <template v-slot:item.place_mark="{ item }">
                <HintingIcon :color="placeIconColor(item)"
                             v-if="placeIcon(item)"
                             :icon="placeIcon(item)"
                             :tooltip="placeTooltip(item)"></HintingIcon>
            </template>

            <template v-slot:item.actions="{ item }">
                <HintingIcon className="mr-2"
                             v-if="!isInspectionExpired(item)"
                             :icon="ICONS.DETAILS"
                             data-test="showDetails"
                             @click="showDetails(item.id)"
                             :tooltip="$t('actions.view')"></HintingIcon>

                <ButtonWithConfirmation :item-id="item.id"
                                        data-test="removeInspection"
                                        v-if="canRemoveInspection"
                                        :callback="deleteInspection"></ButtonWithConfirmation>
            </template>
        </v-data-table>

        <div v-if="showGroupActions"
             class="mt-2">
            <ButtonWithConfirmation :item-id="selected"
                                    v-if="canRemoveInspection"
                                    data-test="removeManyInspections"
                                    :callback="deleteManyInspections">
                <template>
                    <v-btn class="mr-3" text>
                        <v-icon left size="20">{{ ICONS.DELETE }}</v-icon>
                        {{ $t('buttons.remove_selected') }}
                    </v-btn>
                </template>
            </ButtonWithConfirmation>
        </div>

        <InspectionDetails :dialog="detailsVisibility"
                           @visibility="detailsVisibility = $event"
                           @close="closeDetailsDialog"
                           v-if="activeInspection"></InspectionDetails>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { LOCATION_NOTES } from '@/constants/inspectionLocationNotes';
import { ICONS } from '@/constants/icons';
import Pagination from '@/components/widgets/tables/Pagination';
import HintingIcon from '@/components/widgets/HintingIcon';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import InspectionDetails from '@/components/inspections/InspectionDetails';
import inspectionNotesIcons from '@/mixins/inspectionNotesIcons';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';
import GetFile from '@/components/widgets/buttons/GetFile';
import { mapFields } from 'vuex-map-fields';
import visibleHeaders from '@/mixins/visibleHeaders';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'InspectionsTable',
    components: { Pagination, HintingIcon, SortableHeader, InspectionDetails, ButtonWithConfirmation, GetFile },
    mixins: [inspectionNotesIcons, visibleHeaders, paginationSettings],
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        changedPage: {
            type: [String, Number],
            required: false
        },
        xlsLoader: {
            type: Boolean,
            'default': false
        }
    },
    data: (vm) => ({
        pagination: vm.setupPagination(),
        sortable: {
            id: 'desc',
            inspector_name: 'asc',
            customer_name: 'asc',
            point_name: 'asc',
            place_name: 'asc',
            template_name: 'asc',
            territory_name: 'asc',
            inspection_date: 'desc',
            inspection_end_datetime: 'desc',
            scan: 'asc'
        },
        ICONS,
        deleteLoader: false,
        selected: [],
        showConfirmation: false
    }),
    computed: {
        ...mapState('inspections', [
            'inspections',
            'inspectionsTotalAmount'
        ]),
        ...mapState('manageInspection', [
            'activeInspection'
        ]),
        ...mapGetters('systemSettings', [
            'isLocationEnabled'
        ]),
        ...mapFields('manageInspection', [
            'detailsVisibility'
        ]),
        showGroupActions () {
            return this.selected.length > 0;
        },
        canRemoveInspection () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.INSPECTION);
        }
    },
    watch: {
        inspectionsTotalAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        },
        changedPage: {
            handler (val) {
                if (val !== this.pagination.page) {
                    this.pagination.page = val;
                }
            }
        }
    },
    methods: {
        getHeaders () {
            const headers = [
                {
                    text: this.$t('inspections.summary.headers.lp'),
                    value: 'seq',
                    icon: false,
                    sortable: false,
                    visibilityKey: 'INSPECTION_ORDER'
                },
                {
                    text: this.$t('inspections.summary.headers.id'),
                    value: 'id',
                    icon: false,
                    sortable: true
                },
                {
                    text: this.$t('inspections.summary.headers.planned_inspection_date'),
                    value: 'parsed_scheduled_inspection_date_local',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INSPECTION_PLANNED_TIME'
                },
                {
                    text: this.$t('inspections.summary.headers.inspection_time'),
                    value: 'parsed_local_end_date_time',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INSPECTION_EXECUTION_DATE'
                },
                {
                    text: false,
                    value: 'state_mark',
                    icon: ICONS.EXCLAMATION_THICK,
                    sortable: false,
                    visibilityKey: 'INSPECTION_STATUS'
                },
                {
                    text: false,
                    value: 'place_mark',
                    icon: ICONS.MAP,
                    sortable: false,
                    visibilityKey: 'GPS_SHOW_STATUS'
                },
                {
                    text: this.$t('inspections.summary.headers.territory'),
                    value: 'territory_name',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INSPECTION_TERRITORY'
                },
                {
                    text: this.$t('inspections.summary.headers.inspector'),
                    value: 'inspector_name',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INSPECTION_INSPECTOR'
                },
                {
                    text: this.$t('inspections.summary.headers.customer'),
                    value: 'customer_name',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INSPECTION_CUSTOMER'
                },
                {
                    text: this.$t('inspections.summary.headers.site'),
                    value: 'place_name',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INSPECTION_SITE'
                },
                {
                    text: this.$t('inspections.summary.headers.location'),
                    value: 'point_name',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INSPECTION_LOCATION'
                },
                {
                    text: this.$t('inspections.summary.headers.template'),
                    value: 'template_name',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INSPECTION_TEMPLATE'
                },
                {
                    text: this.$t('labels.SCAN_COLUMN'),
                    value: 'scan_checkpoint',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INSPECTION_SCAN'
                },
                {
                    text: this.$t('inspections.summary.headers.actions'),
                    value: 'actions',
                    icon: false,
                    width: '90px',
                    sortable: false
                }
            ];
            return headers.filter(e => this.isVisibleHeader(e.visibilityKey, !!e.negationLogic));
        },
        isInspectionExpired (item) {
            return item.location_note === LOCATION_NOTES.EXPIRED;
        },
        getInspectionDate (item) {
            if (this.isInspectionExpired(item)) {
                return this.$t('inspections.summary.inspection_not_completed');
            }
            return item.parsed_local_end_date_time;
        },
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        sort (event) {
            let val = event;
            if (val === 'parsed_scheduled_inspection_date_local') {
                val = 'inspection_date';
            } else if (val === 'parsed_local_end_date_time') {
                val = 'inspection_end_datetime';
            } else if (val === 'scan_checkpoint') {
                val = 'scan';
            }
            switch (this.sortable[val]) {
            case null:
                this.sortable[val] = 'asc';
                break;
            case 'asc':
                this.sortable[val] = 'desc';
                break;
            case 'desc':
                this.sortable[val] = 'asc';
                break;
            }

            this.$emit('sort', {
                name: val,
                value: this.sortable[val]
            });
        },
        emitFetch () {
            this.$emit('fetch');
        },
        showDetails (id) {
            return this.$store.dispatch('manageInspection/getActiveInspection', id)
                .then(() => {
                    this.detailsVisibility = true;
                });
        },
        showRowDetails (event, { item }) {
            if (!this.isInspectionExpired(item)) {
                this.showDetails(item.id);
            }
        },
        closeDetailsDialog () {
            this.$store.dispatch('manageInspection/clearInspectionData');
            this.detailsVisibility = false;
        },
        deleteInspection (event) {
            return this.$store.dispatch('manageInspection/deleteInspection', event)
                .then(() => {
                    this.emitFetch();
                });
        },
        deleteManyInspections (arrId) {
            return this.$store.dispatch('manageInspection/deleteManyInspections', arrId.map(el => parseInt(el.id)))
                .then(() => {
                    this.selected = [];
                    this.emitFetch();
                });
        },
        emitGetFile () {
            this.$emit('get-file');
        },
        getCssVars (color) {
            return {
                '--template-color': color
            };
        }
    }
};
</script>

<style scoped lang="scss">
    .color-example {
        width: 15px;
        height: 15px;
        display: inline-block;
        border-radius: 50%;
        margin-top: 4px;
        margin-right: 4px;
        background-color: var(--template-color);
    }
</style>
