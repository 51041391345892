import Vue from 'vue';
import Vuex from 'vuex';
import abilityPlugin from '@/store/plugins/ability';
import navigation from './modules/navigation';
import locale from './modules/locale';
import authorization from './modules/authorization';
import inspections from './modules/inspections';
import search from './modules/search';
import filters from './modules/filters';
import qrcodes from './modules/qrcodes';
import assignCustomer from './modules/assignCustomer';
import customers from './modules/customers';
import manageCustomer from './modules/manageCustomer';
import manageSite from './modules/manageSite';
import manageLocation from './modules/manageLocation';
import manageCustomerContact from './modules/manageCustomerContact';
import superadmin from './modules/superadmin';
import generalConfigurations from './modules/generalConfigurations';
import peopleAndNotifications from './modules/peopleAndNotifications';
import incidents from './modules/incidents';
import manageIncident from './modules/manageIncident';
import employees from './modules/employees';
import error from './modules/error';
import sites from './modules/sites';
import manageInspection from './modules/manageInspection';
import locations from './modules/locations';
import sendMail from './modules/sendMail';
import manageTemplate from './modules/manageTemplate';
import templates from './modules/templates';
import reports from './modules/reports';
import customReports from './modules/customReports';
import numberOfIncidents from './modules/numberOfIncidents';
import scheduling from './modules/scheduling';
import employeesGroups from './modules/employeesGroups';
import companies from './modules/companies';
import companyTypes from './modules/companyTypes';
import subContractorsTypes from './modules/subContractorsTypes';
import manageSubContractorsTypes from './modules/manageSubContractorsTypes';
import positions from './modules/positions';
import managePosition from './modules/managePosition';
import manageEmployee from './modules/manageEmployee';
import commonActions from './modules/commonActions';
import cities from './modules/cities';
import manageTemplateQuestion from './modules/manageTemplateQuestion';
import manageEmployeesGroup from './modules/manageEmployeesGroup';
import manageSitesGroup from './modules/manageSitesGroup';
import holidaysCalendar from './modules/holidaysCalendar';
import systemSettings from './modules/systemSettings';
import systemLogTypes from './modules/systemLogTypes';
import microserviceReports from './modules/microserviceReports';
import cyclicRevisions from './modules/cyclicRevisions';
import manageTrack from './modules/manageTrack';
import managePlannedReports from './modules/managePlannedReports';
import subContractors from './modules/subContractors';
import manageSubContractors from './modules/manageSubContractors';
import osm from './modules/osm';
import filterPresets from './modules/filterPresets';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        abilityPlugin
    ],
    modules: {
        navigation,
        locale,
        authorization,
        inspections,
        search,
        filters,
        filterPresets,
        qrcodes,
        assignCustomer,
        customers,
        manageCustomer,
        manageSite,
        manageLocation,
        manageCustomerContact,
        superadmin,
        generalConfigurations,
        peopleAndNotifications,
        incidents,
        manageIncident,
        employees,
        error,
        sites,
        manageInspection,
        locations,
        sendMail,
        manageTemplate,
        templates,
        reports,
        numberOfIncidents,
        customReports,
        scheduling,
        employeesGroups,
        companies,
        companyTypes,
        positions,
        managePosition,
        manageEmployee,
        commonActions,
        cities,
        manageTemplateQuestion,
        manageEmployeesGroup,
        manageSitesGroup,
        systemSettings,
        holidaysCalendar,
        subContractorsTypes,
        manageSubContractorsTypes,
        systemLogTypes,
        microserviceReports,
        cyclicRevisions,
        manageTrack,
        managePlannedReports,
        subContractors,
        manageSubContractors,
        osm
    }
});
