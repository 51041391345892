<template>
    <div class="flex-grow-0">
        <div v-if="$vuetify.breakpoint.smAndDown">
            <v-row>
                <v-col cols="4">
                    <v-btn icon @click="setPage(1)" :disabled="pagination.page === 1">
                        <v-icon medium>{{ ICONS.DOUBLE_LEFT }}</v-icon>
                    </v-btn>
                    <v-btn icon @click="prevPage" :disabled="pagination.page === 1">
                        <v-icon medium>{{ ICONS.SINGLE_LEFT }}</v-icon>
                    </v-btn>
                </v-col>

                <v-col cols="4" align-self="center" class="text-center px-0">
                    <p class="pagination-text mb-0"><span class="fw-600">{{ pagination.page }}</span> / {{ lastPage }}</p>
                </v-col>

                <v-col class="text-right">
                    <v-btn icon @click="nextPage" :disabled="pagination.page === lastPage">
                        <v-icon medium>{{ ICONS.SINGLE_RIGHT }}</v-icon>
                    </v-btn>
                    <v-btn icon @click="setPage(lastPage)" :disabled="pagination.page === lastPage">
                        <v-icon medium>{{ ICONS.DOUBLE_RIGHT }}</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" class="mb-0 pt-0 text--xs">
                    {{$t('labels.all_records')}} : <strong>{{ pagination.amountAll }}</strong>
                </v-col>
            </v-row>
        </div>
        <v-row align-content-center
                  align="center"
                  fill-height
                  v-else>
            <v-col lg="4" md="3">
                <p class="mb-0 text-left text--xs">
                    {{$t('labels.all_records')}}: <strong>{{ pagination.amountAll }}</strong>
                </p>
            </v-col>
            <v-col lg="4" md="5">
                <div class="text-center mb-0">
                    <div class="wrapper">
                        <v-btn icon @click.stop="setPage(1)" :disabled="pagination.page === 1">
                            <v-icon size="20">{{ ICONS.DOUBLE_LEFT }}</v-icon>
                        </v-btn>
                        <v-btn icon @click.stop="prevPage" class="mr-2" :disabled="pagination.page === 1">
                            <v-icon size="20">{{ ICONS.SINGLE_LEFT }}</v-icon>
                        </v-btn>
                        <div v-if="showFirstPage" class="d-inline-flex">
                            <v-btn small
                               outlined @click.stop="setPage(1)"
                               class="mr-2"
                               :class="{'active': pagination.page === 1}">1</v-btn><span v-if="hideRangeStart" class="pr-2">...</span>
                        </div>
                        <v-btn v-for="n in getPageList" :key="n"
                               small
                               outlined
                           class="mr-2"
                           :data-test="`page_${n}`"
                           @click.stop="setPage(n)"
                           :class="{'active': n === pagination.page}">{{ n }} </v-btn>
                        <div  class="d-inline-flex" v-if="showLastPage"><span v-if="hideRangeEnd" class="pr-2">...</span>
                            <v-btn small
                               outlined @click.stop="setPage(lastPage)"
                               class="mr-2"
                               data-test="page_lastPage"
                               :class="{'active': lastPage === pagination.page}">{{ lastPage }}</v-btn>
                        </div>
                        <v-btn icon @click.stop="nextPage" :disabled="pagination.page === lastPage">
                            <v-icon size="20">{{ ICONS.SINGLE_RIGHT }}</v-icon>
                        </v-btn>
                        <v-btn icon @click.stop="setPage(lastPage)" :disabled="pagination.page === lastPage">
                            <v-icon size="20">{{ ICONS.DOUBLE_RIGHT }}</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
            <v-col lg="4">
                <p class="mb-0 text-right">
<!--                    NOTE:    UKRYTY MECHANIZM UTAWIANIA PAGINACJI            -->
<!--                    <span class="wrapper">-->
<!--                        <span class="d-inline-block mr-2 text&#45;&#45;xs">{{ $t('labels.items_per_page') | colons }} </span>-->
<!--                        <a v-for="(item, index) in perPage"-->
<!--                           :key="index"-->
<!--                           :data-test="`amount_${item.amount}`"-->
<!--                           @click.stop="setItemsPerPage(item.amount)"-->
<!--                           :class="{'active': item.amount === pagination.amount, 'mr-2': index < perPage.length -1}">-->
<!--                            {{ item.amount }}-->
<!--                        </a>-->
<!--                    </span>-->
                </p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import filters from '@/constants/filters';
import { ICONS } from '@/constants/icons';

export default {
    name: 'Pagination',
    props: {
        pagination: {},
        perPage: {
            type: Array,
            'default': () => [
                { amount: 20 },
                { amount: 50 },
                { amount: 100 }
            ]
        }
    },
    filters: { ...filters },
    data: () => ({
        ICONS
    }),
    computed: {
        lastPage () {
            return Math.ceil(this.pagination.amountAll / this.pagination.amount);
        },
        getPageList () {
            const startLimit = (this.pagination.page === this.lastPage) ? 3 : 2;
            const start = Math.max(0, this.pagination.page - startLimit);
            const end = Math.min(start + 3, this.lastPage);
            const pages = [];
            for (let i = 1; i <= this.lastPage; i++) {
                pages.push(i);
            }
            return pages.slice(start, end);
        },
        showLastPage () {
            return this.lastPage > 3 && this.pagination.page + 1 < this.lastPage;
        },
        showFirstPage () {
            return this.pagination.page >= 3;
        },
        hideRangeStart () {
            return this.pagination.page > 3;
        },
        hideRangeEnd () {
            return this.lastPage !== 4 && this.pagination.page + 2 !== this.lastPage;
        }
    },
    methods: {
        setPage (value) {
            this.$emit('change-page', value);
        },
        setItemsPerPage (value) {
            this.$emit('change-amount', value);
        },
        nextPage () {
            if (this.pagination.page < this.lastPage) {
                this.setPage(this.pagination.page + 1);
            }
        },
        prevPage () {
            if (this.pagination.page > 1) {
                this.setPage(this.pagination.page - 1);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "@/assets/css/_variables";
    ::v-deep {
        & .active {
            background: $secondary;
            color: #fff;
            border-color: $secondary;
        }
    }

    .wrapper {
        display: inline-flex;
        align-items: center;
    }
</style>
