<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="d-contents">
        <v-row align-center
               :wrap="$vuetify.breakpoint.xsOnly"
               no-gutters
               justify-space-between
               class="flex-grow-0 mb-3">
            <v-col cols="auto" class="mr-3">
                <v-btn text block
                   @click="emitFetch">
                    <v-icon left size="20">{{ ICONS.REFRESH }}</v-icon>
                    {{ $t('buttons.refresh_list') }}
                </v-btn>
            </v-col>
            <v-col cols="auto" class="mr-3">
                <GetFile :title="$t('buttons.export_xls')"
                         :loader="xlsLoader"
                         :asBlock="true"
                         @get-file="emitGetFile">
                    <template slot="icon">
                        <v-icon left
                                size="20">{{ ICONS.EXPORT }}
                        </v-icon>
                    </template>
                </GetFile>
            </v-col>
            <v-col cols="12" sm="auto" class="mt-3 mt-sm-0 ml-auto">
                <v-btn color="secondary"
                       v-if="canAddIncident"
                       data-test="addNewIncidentBtn"
                       :block="$vuetify.breakpoint.xsOnly"
                       @click="openEditForm()">
                    <v-icon left size="20">{{ ICONS.ADD }}</v-icon>
                    {{ $t('incidents.add_new_incident') }}
                </v-btn>
            </v-col>
        </v-row>

        <Pagination :pagination="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>

        <v-data-table :headers="getHeaders()"
                      :loading="loader"
                      disable-sort
                      v-model="selected"
                      :items="incidents"
                      disable-pagination
                      hide-default-footer
                      @dblclick:row="showRowDetails"
                      @contextmenu:row="showContextMenu"
                      show-select
                      height="100%"
                      fixed-header
                      item-key="number"
                      class="elevation-2 table-wrapper">

            <template v-for="(headerItem, i) in getHeaders()"
                      v-slot:[`header.${headerItem.value}`]="{ header }">
                <SortableHeader :header-item="header"
                                :key="i"
                                @sort="sort($event)"></SortableHeader>
            </template>

            <template v-slot:item.number="{ item }">
                <span @click="showDetailsDialog(item)"
                      class="clickable">{{ item.number }}</span>
            </template>

            <template v-slot:item.incident_type="{ item }">
                <Type v-if="item.type_id"
                      :type="item.incident_type"></Type>
            </template>

            <template v-slot:item.status="{ item }">
                <Status v-if="item.status_id"
                        :status="item.status"></Status>
            </template>

            <template v-slot:item.actions="{ item }">
                <HintingIcon class-name="mr-2"
                             :icon="ICONS.DETAILS"
                             data-test="showDetailsBtn"
                             @click="showDetailsDialog(item)"
                             :tooltip="$t('actions.view')"></HintingIcon>

                <HintingIcon class-name="mr-2"
                             :icon="ICONS.EDIT"
                             :disabled="!item.is_editable"
                             v-if="canEditIncident"
                             @click="openEditForm(item)"
                             data-test="editIncidentBtn"
                             :tooltip="$t('actions.edit')"></HintingIcon>

                <ButtonWithConfirmation :item-id="item.id"
                                        v-if="canRemoveIncident"
                                        :callback="deleteIncident"></ButtonWithConfirmation>
            </template>
        </v-data-table>

        <v-layout v-if="showGroupActions"
                  class="mt-3 flex-grow-0"
                  :wrap="$vuetify.breakpoint.smAndDown"
                  align-center>
            <ButtonWithConfirmation :item-id="selected"
                                    v-if="canRemoveIncident"
                                    :callback="deleteManyIncidents">
                <template>
                    <v-btn class="mr-3" text>
                        <v-icon left size="20">{{ ICONS.DELETE }}</v-icon>
                        {{ $t('buttons.remove_selected') }}
                    </v-btn>
                </template>
            </ButtonWithConfirmation>
            <div class="mr-3"
                 v-if="canEditIncident">
                <v-btn text @click="showChangeStatuses">
                    <v-icon left size="20">{{ ICONS.REPEAT }}</v-icon>
                    {{ $t('buttons.change_status_of_selected') }}
                </v-btn>
                <ChangeStatuses :dialog="changeStatusesVisibility"
                                :incidents="selected"
                                @visibility="changeStatusesVisibility = $event"
                                @changed-statuses="handleChangedStatuses"
                                @close="closeChangeStatuses"></ChangeStatuses>
            </div>
        </v-layout>

        <IncidentDetails :dialog="showDetails"
                         data-test="detailsDialog"
                         @visibility="showDetails = $event"
                         @close="closeDetailsDialog"
                         v-if="activeIncident"></IncidentDetails>

        <v-menu
            v-model="contextMenuVisible"
            :position-x="contextMenuX"
            :position-y="contextMenuY"
            :close-on-content-click="false"
            absolute
            offset-y
        >
            <v-list>
                <v-list-item @click="showDetailsDialog(contextMenuItem)">
                    <v-list-item-title class="d-flex align-center">
                        <v-icon left dense>{{ ICONS.DETAILS }}</v-icon>{{ $t('actions.view') }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="canEditIncident" @click="openEditForm(contextMenuItem)" :disabled="contextMenuItem &&!contextMenuItem.is_editable">
                    <v-list-item-title class="d-flex align-center">
                        <v-icon left dense>{{ ICONS.EDIT }}</v-icon>{{ $t('actions.edit') }}
                    </v-list-item-title>
                </v-list-item>
                <v-divider v-if="canRemoveIncident" class="my-2" />
                <v-list-item v-if="canRemoveIncident && !confirmDelete" @click.prevent="confirmDelete = true">
                    <v-list-item-title class="d-flex align-center">
                        <v-icon left dense>{{ ICONS.DELETE }}</v-icon>{{ $t('actions.delete') }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="canRemoveIncident && confirmDelete" @click="deleteIncident(contextMenuItem.id)">
                    <v-list-item-title class="d-flex align-center error--text">
                        <v-icon left dense>{{ ICONS.DELETE_CONFIRM }}</v-icon>{{ $t('messages.delete_confirmation') }}
                    </v-list-item-title>
                </v-list-item>
              </v-list>
       </v-menu>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Pagination from '@/components/widgets/tables/Pagination';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import HintingIcon from '@/components/widgets/HintingIcon';
import Type from '@/components/widgets/Type';
import Status from '@/components/widgets/Status';
import IncidentDetails from '@/components/incidents/IncidentDetails';
import { ICONS } from '@/constants/icons';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';
import GetFile from '@/components/widgets/buttons/GetFile';
import ChangeStatuses from '@/components/incidents/ChangeStatuses';
import visibleHeaders from '@/mixins/visibleHeaders';
import paginationSettings from '@/mixins/paginationSettings';

const SORTING_PARAMS = {
    number: 'number',
    incidents_date: 'date_of_occurrence',
    end_date: 'date_ending',
    employee_full_name: 'employee_name',
    location_name: 'location__name',
    point_name: 'point__name',
    location_client_name: 'client_name',
    assigned_employee_full_name: 'assigned_employee_name',
    incident_type: 'incident_type__name',
    status: 'status__name',
    scan_field_answer: 'scan'
};

export default {
    name: 'IncidentsTable',
    components: {
        Pagination,
        SortableHeader,
        HintingIcon,
        Status,
        Type,
        IncidentDetails,
        ButtonWithConfirmation,
        GetFile,
        ChangeStatuses
    },
    mixins: [visibleHeaders, paginationSettings],
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        xlsLoader: {
            type: Boolean,
            'default': false
        },
        changedPage: {
            type: [String, Number],
            required: false
        }
    },
    data: (vm) => ({
        pagination: vm.setupPagination(),
        sortable: {
            number: null,
            incidents_date: 'desc',
            end_date: null,
            employee_full_name: null,
            location_name: null,
            point_name: null,
            location_client_name: null,
            assigned_employee_full_name: null,
            incident_type: null,
            status: null,
            scan_field_answer: 'asc',
            seq: null
        },
        selected: [],
        ICONS,
        changeStatusesVisibility: false,
        contextMenuVisible: false,
        contextMenuItem: null,
        contextMenuX: 0,
        contextMenuY: 0,
        confirmDelete: false
    }),
    computed: {
        ...mapState('incidents', [
            'incidents',
            'totalIncidentsAmount',
            'incidentTypes',
            'incidentStatuses'
        ]),
        ...mapState('manageIncident', [
            'activeIncident'
        ]),
        ...mapFields('manageIncident', [
            'incidentFormVisibility',
            'editedIncidentId',
            'showDetails'
        ]),
        showGroupActions () {
            return this.selected.length > 0;
        },
        canRemoveIncident () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.INCIDENT);
        },
        canAddIncident () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.INCIDENT);
        },
        canEditIncident () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.INCIDENT);
        }
    },
    watch: {
        totalIncidentsAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        },
        changedPage: {
            handler (val) {
                if (val !== this.pagination.page) {
                    this.pagination.page = val;
                }
            }
        },
        contextMenuVisible: {
            handler (val) {
                if (!val) {
                    this.contextMenuItem = null;
                    this.confirmDelete = false;
                }
            }
        }
    },
    methods: {
        getHeaders () {
            const headers = [
                {
                    text: this.$t('incidents.table.headers.seq'),
                    value: 'seq',
                    icon: false,
                    sortable: false,
                    visibilityKey: 'INCIDENT_SEQ'
                },
                {
                    text: this.$t('incidents.table.headers.number'),
                    value: 'number',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INCIDENT_NUMBER'
                },
                {
                    text: this.$t('labels.location_time_from'),
                    value: 'parsed_local_start_date_time',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INCIDENT_START'
                },
                {
                    text: this.$t('labels.location_time_to'),
                    value: 'parsed_local_end_date_time',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INCIDENT_END'
                },
                {
                    text: this.$t('incidents.table.headers.customer'),
                    value: 'location.client.name',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INCIDENT_CUSTOMER'
                },
                {
                    text: this.$t('incidents.table.headers.site'),
                    value: 'location.name',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INCIDENT_SITE'
                },
                {
                    text: this.$t('incidents.table.headers.location'),
                    value: 'point.name',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INCIDENT_LOCATION'
                },
                {
                    text: this.$t('incidents.table.headers.person'),
                    value: 'employee.full_name',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INCIDENT_PERSON'
                },
                {
                    text: this.$t('incidents.table.headers.person_assigned'),
                    value: 'assigned_employee.full_name',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INCIDENT_PERSON_ASSIGNED'
                },
                {
                    text: this.$t('incidents.table.headers.type'),
                    value: 'incident_type',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INCIDENT_TYPE'
                },
                {
                    text: this.$t('incidents.table.headers.status'),
                    value: 'status',
                    icon: false,
                    width: '100px',
                    sortable: true,
                    visibilityKey: 'INCIDENT_STATUS'
                },
                {
                    text: this.$t('incidents.table.headers.description'),
                    value: 'description',
                    icon: false,
                    sortable: false,
                    visibilityKey: 'INCIDENT_DESCRIPTION'
                },
                {
                    text: this.$t('labels.SCAN_COLUMN'),
                    value: 'scan_field_answer',
                    icon: false,
                    sortable: true,
                    visibilityKey: 'INCIDENT_DISPLAY_SCAN_FIELD'
                },
                {
                    text: this.$t('incidents.table.headers.actions'),
                    value: 'actions',
                    icon: false,
                    width: '120px',
                    sortable: false
                }
            ];
            return headers.filter(e => this.isVisibleHeader(e.visibilityKey, !!e.negationLogic));
        },
        openEditForm (item = null) {
            if (item) {
                this.$store.dispatch('manageIncident/getActiveIncident', item.id)
                    .then(() => {
                        this.$store.commit('manageIncident/SET_INCIDENT_DATA', this.activeIncident);
                        this.incidentFormVisibility = true;
                    });
            } else {
                this.incidentFormVisibility = true;
            }
            this.editedIncidentId = item?.id || null;
            this.contextMenuVisible = false;
        },
        showDetailsDialog (item) {
            this.editedIncidentId = item.id;
            this.$store.dispatch('manageIncident/getActiveIncident', item.id)
                .then(() => {
                    if (item.source_inspection_id) {
                        this.$store.dispatch('manageInspection/getActiveInspection', item.source_inspection_id);
                    }
                    this.showDetails = true;
                });
            this.contextMenuVisible = false;
        },
        showRowDetails (event, { item }) {
            this.showDetailsDialog(item);
        },
        closeDetailsDialog () {
            this.editedIncidentId = null;
            this.$store.dispatch('manageIncident/clearIncidentData');
            this.$store.commit('customers/SET_FOCUSED_FIELD', null);
            this.$store.commit('manageIncident/CLEAR_INCIDENT_DATA');
            this.$store.commit('manageInspection/SET_ACTIVE_INSPECTION', null);
            this.showDetails = false;
        },
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        sort (event) {
            let val = event.replaceAll('.', '_');
            if (val === 'parsed_local_start_date_time') {
                val = 'incidents_date';
            } else if (val === 'parsed_local_end_date_time') {
                val = 'end_date';
            }
            switch (this.sortable[val]) {
            case null:
                this.sortable[val] = 'desc';
                break;
            case 'asc':
                this.sortable[val] = 'desc';
                break;
            case 'desc':
                this.sortable[val] = 'asc';
                break;
            }
            this.$emit('sort', {
                name: SORTING_PARAMS[val],
                value: this.sortable[val]
            });
        },
        emitFetch () {
            this.$emit('fetch');
        },
        deleteIncident (id) {
            return this.$store.dispatch('manageIncident/deleteIncident', id)
                .then(() => {
                    this.emitFetch();
                });
        },
        deleteManyIncidents (arrId) {
            return this.$store.dispatch('manageIncident/deleteManyIncidents', arrId.map(el => parseInt(el.id)))
                .then(() => {
                    this.emitFetch();
                    this.selected = [];
                });
        },
        emitGetFile () {
            this.$emit('get-file');
        },
        showChangeStatuses () {
            this.changeStatusesVisibility = true;
        },
        closeChangeStatuses () {
            this.changeStatusesVisibility = false;
        },
        handleChangedStatuses () {
            this.emitFetch();
            this.selected = [];
        },
        showContextMenu (event, { item }) {
            event.preventDefault();
            this.contextMenuVisible = false;
            this.contextMenuX = event.clientX;
            this.contextMenuY = event.clientY;
            this.$nextTick(() => {
                this.contextMenuVisible = true;
                this.contextMenuItem = item;
            });
        }
    }
};
</script>

<style scoped lang="scss">
::v-deep {
    & .v-select {
        max-width: 210px;

        & .v-text-field__details {
            display: none;
        }
    }

    & .v-list-item {
        min-height: 40px;
    }

    & .v-list-item__title {
        font-size: 0.815rem;
    }
}
.table-wrapper {
    overflow: hidden;
}
.gap-3 {
    gap: 12px;
}
</style>
