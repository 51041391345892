<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <ValidationProvider :name="$attrs.label"
                        :vid="name"
                        :rules="rules"
                        v-slot="{ errors }"
                        tag="div">
        <v-autocomplete  outlined
                         :error-messages="errors"
                         v-bind="$attrs"
                         v-on="$listeners">
            <template slot="label">
                {{ $attrs.label }}
                <span v-if="isRequired" class="error--text">*</span>
            </template>
            <template v-slot:selection="data">
                <span v-if="data.index === 0 || !$attrs.multiple" class="mr-1">{{ data.item.text }}</span>
                <span v-if="$attrs.multiple && $attrs.value.length > 1 && data.index === 1"
                      class="grey--text caption">(+{{ $attrs.value.length  - 1 }})</span>
            </template>
            <template v-slot:item="{ item, on, attrs }">
                <InfiniteScroll :item="item"
                                @load-more-items="loadMoreItems"
                                :index="itemIndex(item)"
                                :items-length="itemsLength">
                    <v-layout align-center>
                        <Checkbox v-if="$attrs.multiple"
                                  v-bind:value="attrs.inputValue"
                                  :label="item.text"
                                  v-on="on">
                        </Checkbox>
                        <span v-else>{{ item.text }}</span>
                        <span v-if="checkDefault">&nbsp;{{ item.isDefault ? `(${$t('labels.default')})` : '' }}</span>
                    </v-layout>
                </InfiniteScroll>
            </template>
            <template :slot="slotName">
                <slot :name="slotName"></slot>
            </template>
        </v-autocomplete>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import InfiniteScroll from '@/components/widgets/InfiniteScroll';
import Checkbox from '@/components/widgets/forms/Checkbox';
import isFieldRequired from '@/mixins/isFieldRequired';

export default {
    name: 'Autocomplete',
    components: { ValidationProvider, InfiniteScroll, Checkbox },
    mixins: [isFieldRequired],
    props: {
        slotName: {
            type: String,
            'default': ''
        },
        name: {
            type: String,
            'default': ''
        },
        checkDefault: {
            type: Boolean,
            'default': false
        },
        rules: {
            type: [String, Object, Array],
            'default': ''
        }
    },
    computed: {
        itemsLength () {
            return this.$attrs.items.length;
        }
    },
    methods: {
        itemIndex (item) {
            return this.$attrs.items.findIndex(el => el.value === item.value);
        },
        loadMoreItems () {
            this.$emit('load-more-items');
        }
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";
    ::v-deep {
        & .v-input__icon--clear {
            & button {
                &::before {
                    font-size: 16px;
                }
            }
        }

        & .theme--light.v-input.v-input--checkbox {
            margin-top: 0;
            & input {
                visibility: hidden;
            }
            & .v-messages {
                display: none;
            }
        }

        & .v-input--is-disabled {
            & .v-messages__message {
                font-size: $xxs;
            }
        }
    }
</style>
