<template>
    <Modal :modal-title="$t('defined_filters.modal_title')"
           :dialog="dialog"
           max-width="1000px"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <slot />
            <SavePresetModal :dialog="savePresetModalVisibility" @close="closeSavePresetModal" />
        </template>
        <template slot="footer">
            <v-btn
                color="secondary"
                outlined
                @click="close"
            >
                {{ $t('buttons.ok') }}
            </v-btn>
            <v-btn
                v-if="canEditIncident"
                color="secondary"
                :disabled="!hasActiveFilters"
                @click="savePresetModalVisibility = !savePresetModalVisibility"
            >
                <v-icon
                    size="24"
                    color="white"
                    left
                >
                    {{ ICONS.SAVE }}
                </v-icon>
                {{ $t('buttons.save_preset') }}
            </v-btn>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/templates/Modal.vue';
import { ICONS } from '@/constants/icons';
import SavePresetModal from '@/components/widgets/tables/FilterPresets/SavePresetModal.vue';
import { mapState } from 'vuex';
import { isFilterActive } from '@/components/widgets/tables/FilterPresets/utils';

export default {
    name: 'FiltersModal',
    components: { SavePresetModal, Modal },
    data: () => ({
        savePresetModalVisibility: false,
        ICONS
    }),
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapState('filters', [
            'filters'
        ]),
        canEditIncident () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.INCIDENT);
        },
        hasActiveFilters () {
            return Object.keys(this.filters).some(key => isFilterActive(this.filters[key]));
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        visibility (event) {
            this.$emit('visibility', event);
        },
        closeSavePresetModal () {
            this.savePresetModalVisibility = false;
            this.close();
        }
    }
};
</script>
