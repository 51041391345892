<template>
    <v-chip-group v-if="filterChips.length > 0" :column="column" :class="{'clickable': clickable}" active-class="secondary" v-model="selected">
        <v-chip
            v-for="filter in filterChips"
            :key="filter.key"
            :value="filter.key"
            label
            :dark="isPresetActive(filter.key)"
            :close="filter.isPreset || Boolean(resetFilter)"
            @click="clickable && filter.isPreset && toggleActive(filter)"
            @click:close="filter.isPreset ? hidePreset(filter.key) : resetFilter(filter.key)"
        >
            <template v-if="filter.isPreset">
                <strong class="chip-value">{{ filter.value }}</strong>
            </template>
            <template v-else>
                {{ $t(`labels.${filter.key}`) }}: <strong class="chip-value ml-2">{{ filter.value }}</strong>
            </template>
        </v-chip>
    </v-chip-group>
</template>

<script>
import { isFilterActive } from '@/components/widgets/tables/FilterPresets/utils';
import { FILTERS_V2 } from '@/constants/filtersModelNames';
import { mapState } from 'vuex';

export default {
    name: 'FiltersChips',
    data: () => ({
        selected: null
    }),
    props: {
        filters: {
            type: Object
        },
        presets: {
            type: Array,
            'default': () => []
        },
        resetFilter: {
            type: Function || null
        },
        column: {
            type: Boolean,
            'default': false
        },
        clickable: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        ...mapState('filterPresets', [
            'activePreset'
        ]),
        filterChips () {
            const chips = [];

            if (this.filters) {
                const filterItems = Object.keys(this.filters).filter(key => isFilterActive(this.filters[key])).map(key => {
                    const filterValue = Array.isArray(this.filters[key]) ? this.filters[key].map(v => v.text).join(', ') : this.filters[key];

                    if (key === FILTERS_V2.IS_COMPLETED) {
                        return { key, value: filterValue.value ? this.$t('answers.yes') : this.$t('answers.no') };
                    }

                    return { key, value: filterValue };
                });
                chips.push(...filterItems);
            }

            if (this.presets.length !== 0) {
                const presetItems = this.presets.map(preset => ({ key: preset.id, value: preset.name, isPreset: true }));
                chips.push(...presetItems);
            }

            return chips;
        }
    },
    methods: {
        hidePreset (id) {
            return this.$store.dispatch('filterPresets/hidePreset', id);
        },
        toggleActive (preset) {
            return this.$store.dispatch('filterPresets/toggleActive', preset);
        },
        isPresetActive (key) {
            return this.selected === key;
        }
    },
    watch: {
        activePreset (val) {
            this.selected = val?.key || null;
        },
        deep: true
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables";

    ::v-deep {
        .v-chip-group:not(.clickable) & .v-chip {
            pointer-events: none;

            button {
                pointer-events: all;
            }
        }
    }
</style>
