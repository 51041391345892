import { getV2Endpoint } from '@/utils/apiV2Endpoints';
import KmsHttpService from '@/servises/kmsHttpService';

export default {
    getFilterPresets: (params) => {
        const endpoint = getV2Endpoint({ 'group': 'filter_presets', 'endpoint': 'list' }, null);
        return KmsHttpService.get(endpoint, params);
    },
    createFilterPreset: (params) => {
        const endpoint = getV2Endpoint({ 'group': 'filter_presets', 'endpoint': 'list' }, null);
        return KmsHttpService.post(endpoint, params);
    },
    updateFilterPreset: (id, params) => {
        const endpoint = getV2Endpoint({ 'group': 'filter_presets', 'endpoint': 'manage' }, { id: id });
        return KmsHttpService.patch(endpoint, params);
    },
    deleteFilterPreset: (id) => {
        const endpoint = getV2Endpoint({ 'group': 'filter_presets', 'endpoint': 'manage' }, { id: id });
        return KmsHttpService.delete(endpoint, null);
    }
};
