<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ setup.title }}</h1>
        <v-row justify="end" v-if="setup.ability.c">
            <slot name="secondButton"></slot>
            <v-btn color="secondary"
                   @click="editItem(null)">{{ setup.newItemTitle || 'New' }}
            </v-btn>
        </v-row>
        <v-row v-if="setup.searchBy">
            <v-col cols="12" md="6">
                <TextField v-model.trim="search"
                           autocomplete="off"
                           clearable
                           @click:clear="search = ''"
                           :label="$t(`labels.${setup.searchBy}`)"
                           slotName="append">
                    <template slot="slotContent">
                        <v-icon color="medium_text">{{ ICONS.SEARCH }}</v-icon>
                    </template>
                </TextField>
            </v-col>
            <slot name="search">
            </slot>
        </v-row>
        <slot name="upperRow"></slot>
        <slot name="fetchData"></slot>
        <v-data-table
            v-model="selected"
            :show-select="setup.showSelect || false"
            :headers="setup.headers"
            :items="items"
            :item-class="setup.itemClass"
            :loading="loading"
            class="elevation-1"
            disable-pagination
            hide-default-footer
            disable-sort>
            <template v-for="(headerItem, i) in setup.headers"
                      v-slot:[`header.${headerItem.value}`]="{ header }">
                <SortableHeader :header-item="header"
                                :key="i"
                                @sort="sort($event)"></SortableHeader>
            </template>
            <template v-slot:item.actions="{ item }">
                <HintingIcon v-if="setup.ability.r"
                             :icon="ICONS.DETAILS"
                             tooltip="Show"
                             :id="item.id"
                             class-name="mr-4"
                             data-test="handleViewBtn"
                             @click="showItem(item)">
                </HintingIcon>

                <HintingIcon v-if="setup.ability.u"
                             :icon="ICONS.EDIT"
                             tooltip="Edit"
                             :id="item.id"
                             data-test="showCustomerFormBtn"
                             @click="editItem(item)"
                             class-name="mr-4">
                </HintingIcon>
                <HintingIcon v-if="setup.ability.d"
                             :icon="ICONS.DELETE"
                             tooltip="Delete"
                             :id="item.id"
                             data-test="showCustomerFormBtn"
                             @click="tryDeleteItem(item)">
                </HintingIcon>
            </template>
            <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
                <slot :name="name" v-bind="item"></slot>
            </template>

        </v-data-table>
        <Pagination :pagination="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>
        <v-dialog
            v-model="deleteDialog"
            persistent
            max-width="390"
        >
            <v-card>
                <v-card-title>
                    {{ setup.deleteInfo || 'Are you sure you want to delete ?' }}
                </v-card-title>
                <v-layout align-center justify-center class="py-5">
                    <v-card-actions>
                        <v-btn
                            color="secondary"
                            outlined
                            class="mr-3"
                            @click="cancelDeleteItem">
                            No
                        </v-btn>
                        <v-btn
                            color="secondary"
                            class="mr-3"
                            @click="deleteItem">
                            Yes
                        </v-btn>
                    </v-card-actions>
                </v-layout>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';
import Pagination from '@/components/widgets/tables/Pagination';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import TextField from '@/components/widgets/forms/TextField';
import { ICONS } from '@/constants/icons';
import debounce from 'lodash.debounce';
import HintingIcon from '@/components/widgets/HintingIcon';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'DefaultTable',
    components: { Pagination, SortableHeader, TextField, HintingIcon },
    mixins: [paginationSettings],
    props: {
        setup: {
            type: Object,
            required: true
        }
    },
    data: (vm) => ({
        ICONS,
        selected: [],
        search: '',
        deleteDialog: false,
        itemToDelete: null,
        items: [],
        loading: true,
        sortingColumn: null,
        pagination: vm.setupPagination()
    }),
    watch: {
        sortingColumn: {
            handler (val) {
                if (val) {
                    this.fetchData();
                }
            },
            deep: true
        },
        selected: {
            handler (val) {
                this.$emit('selected', val);
            },
            immediate: true
        },
        totalPositionsAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        },
        changedPage: {
            handler (val) {
                if (val !== this.pagination.page) {
                    this.pagination.page = val;
                }
            }
        },
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.pagination.page = 1;
                    this.getLazyData();
                }
            }
        }
    },
    methods: {
        getLazyData: debounce(function () {
            this.fetchData();
        }, 1000),
        changePage (event) {
            this.pagination.page = event;
            this.fetchData();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.fetchData();
        },
        sort (event) {
            switch (this.setup.sortable[event]) {
            case null:
                this.setup.sortable[event] = 'desc';
                break;
            case 'asc':
                this.setup.sortable[event] = 'desc';
                break;
            case 'desc':
                this.setup.sortable[event] = 'asc';
                break;
            }
            this.sortingColumn = {
                name: this.setup.sortingKeys && event in this.setup.sortingKeys ? this.setup.sortingKeys[event] : event,
                value: this.setup.sortable[event]
            };
        },
        editItem (item) {
            if (item) {
                this.$emit('edit-item', item);
            } else this.$emit('edit-item', null);
        },
        tryDeleteItem (item) {
            this.deleteDialog = true;
            this.itemToDelete = item;
        },
        cancelDeleteItem () {
            this.deleteDialog = false;
            this.itemToDelete = null;
        },
        deleteItem () {
            this.$emit('delete-item', this.itemToDelete);
            this.deleteDialog = false;
        },
        showItem (item) {
            this.$emit('show-item', item);
        },
        emitResponse (payload) {
            this.$emit('response', payload);
        },
        fetchData () {
            const params = {
                'stats[total]': 'count',
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount
            };

            if (this.setup.fetchParams) {
                Object.keys(this.setup.fetchParams).forEach(k => {
                    params[k] = this.setup.fetchParams[k];
                });
            }

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }
            if (this.setup.customSearchFilter && this.setup.searchBy && this.search) {
                params[`filter[${this.setup.customSearchFilter}]`] = this.search;
            } else if (this.setup.searchBy && this.search) {
                params[`filter[${this.setup.searchBy}][match]`] = this.search;
            }
            this.loading = true;
            return HttpService.get(getEndpoint(this.setup.endpoint), {
                params: params
            }).then((resp) => {
                if (this.setup.customItems) {
                    this.items = this.setup.customItems(resp.data.data, resp.data.included).map((i, index) => {
                        return {
                            seq: (index + 1) + ((params['page[number]'] - 1) * params['page[size]']),
                            ...i
                        };
                    });
                } else {
                    this.items = resp.data.data.map((e, index) => {
                        return {
                            seq: (index + 1) + ((params['page[number]'] - 1) * params['page[size]']),
                            id: e.id,
                            type: e.type,
                            ...e.attributes
                        };
                    });
                }
                this.pagination.amountAll = resp.data.meta.stats.total.count;
                this.loading = false;
                this.emitResponse(resp);
            });
        },
        resetSorting () {
            this.sortingColumn = null;
        }

    },
    created () {
        this.fetchData();
    }
};
</script>
