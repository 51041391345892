<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-data-table :headers="headers"
                      :items="locationsPerSite"
                      disable-pagination
                      dense
                      :loading="loading"
                      @dblclick:row="showRowDetails"
                      hide-default-footer
                      disable-sort
                      class="elevation-1">
            <template v-slot:item.name="{ item }">
                <span class="clickable"
                      @click="handleView(item)">{{ item.name }}</span>
            </template>
            <template v-slot:item.updated_at="{ item }">
                {{ toLocalDateTime(item.updated_at) }}
            </template>
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header }">
                <SortableHeader :header-item="header"
                                :key="i"
                                @sort="sort($event)"></SortableHeader>
            </template>

            <template v-slot:item.special_attention="{ item }">
                <v-layout justify-center>
                    <v-icon color="red"
                            v-if="item.special_attention">{{ ICONS.CHECK }}</v-icon>
                </v-layout>
            </template>

            <template v-slot:item.actions="{ item }">
                <div>
                    <HintingIcon :icon="ICONS.DETAILS"
                                 :tooltip="$t('actions.view')"
                                 :id="item.id"
                                 class-name="mr-2"
                                 @click="handleView(item)"></HintingIcon>

                    <HintingIcon :icon="ICONS.EDIT"
                                 :tooltip="$t('actions.edit')"
                                 v-if="canEditLocation"
                                 @click="editItem(item)"
                                 class-name="mr-2"></HintingIcon>

                    <ButtonWithConfirmation :item-id="item.id"
                                            v-if="canRemoveLocation"
                                            class-name="mr-2"
                                            :callback="deleteLocation"></ButtonWithConfirmation>

                    <GetFile :with-title="false"
                            :loader="getLoader(item.id)"
                            @get-file="getQRCode(item.id)">
                        <template slot="icon">
                            <HintingIcon :icon="ICONS.QR_CODE"
                                         :tooltip="$t('management.customers_and_sites.locations_table.actions.qr')"
                                         :id="item.id"></HintingIcon>
                        </template>
                    </GetFile>
                </div>
            </template>
        </v-data-table>
        <Pagination :pagination="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>
        <LocationDetails v-if="showLocationDetails"
                         :dialog="showLocationDetails"
                         @visibility="showLocationDetails = $event"
                         @close="close"></LocationDetails>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import HintingIcon from '@/components/widgets/HintingIcon';
import LocationDetails from '@/components/customers/LocationDetails';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';
import GetFile from '@/components/widgets/buttons/GetFile';
import { ICONS } from '@/constants/icons';
import Pagination from '@/components/widgets/tables/Pagination';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';
import { CONVERT_FROM_UTC, PARSE_DATE, PARSE_TIME } from '@/helpers/dates';

export default {
    name: 'LocationsPerSiteTable',
    components: { HintingIcon, LocationDetails, ButtonWithConfirmation, GetFile, Pagination, SortableHeader },
    props: {
        edit: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
        ICONS,
        sortingColumn: { name: 'updated_at', value: 'desc' },
        loaders: [],
        loading: true,
        pagination: {
            page: 1,
            amount: 20,
            amountAll: null
        },
        sortable: {
            updated_at: 'asc',
            name: ''
        }
    }),
    computed: {
        headers () {
            return [
                {
                    text: this.$t('management.customers_and_sites.locations_table.headers.name'),
                    value: 'name',
                    sortable: true
                },
                {
                    text: this.$t('labels.updated_at'),
                    value: 'updated_at',
                    sortable: true
                },
                {
                    text: this.$t('management.customers_and_sites.locations_table.headers.actions'),
                    value: 'actions',
                    width: '150px',
                    sortable: false
                }
            ];
        },
        ...mapState('customers', [
            'locationsPerSite',
            'update'
        ]),
        ...mapState('manageSite', [
            'activeSite'
        ]),
        ...mapState('manageLocation', [
            'activeLocation'
        ]),
        ...mapFields('manageLocation', [
            'locationFormVisibility',
            'editedLocationId',
            'showLocationDetails'
        ]),
        canEditLocation () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.LOCATION);
        },
        canRemoveLocation () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.LOCATION);
        }
    },
    watch: {
        locationsPerSite: {
            handler (val) {
                if (val && val.length > 0) {
                    val.forEach(el => {
                        this.loaders.push({
                            loader: false,
                            id: el.id
                        });
                    });
                } else {
                    this.loaders = [];
                }
            },
            immediate: true
        },
        sortingColumn: {
            handler () {
                this.getLocationsPerSite();
            },
            deep: true
        },
        update: {
            handler (val) {
                if (val === true) {
                    this.getLocationsPerSite();
                }
            }
        }
    },
    methods: {
        toLocalDateTime (e) {
            return PARSE_DATE(CONVERT_FROM_UTC(e)) + ' ' + PARSE_TIME(CONVERT_FROM_UTC(e));
        },
        handleView (item) {
            this.$store.dispatch('manageLocation/getActiveLocation', item.id)
                .then(() => {
                    this.showLocationDetails = true;
                });
        },
        close () {
            this.$store.dispatch('manageLocation/cleanActiveLocationInformation');
            this.showLocationDetails = false;
        },
        editItem (item) {
            this.$store.dispatch('manageLocation/getActiveLocation', item.id)
                .then(() => {
                    this.$store.commit('manageLocation/SET_LOCATION_DATA', this.activeLocation);
                    this.editedLocationId = item.id;
                    this.locationFormVisibility = true;
                });
        },
        emitAction (val) {
            this.$emit('switch', val);
        },
        deleteLocation (id) {
            return this.$store.dispatch('manageLocation/deleteLocation', id)
                .then(() => {
                    this.getLocationsPerSite();
                });
        },
        getQRCode (id) {
            this.setLoader(id, true);

            return this.$store.dispatch('manageLocation/getLocationQrCode', id)
                .finally(() => {
                    this.setLoader(id, false);
                });
        },
        getLoader (id) {
            return this.loaders.find(el => el.id === id)?.loader || false;
        },
        setLoader (id, val) {
            this.loaders.find(el => el.id === id).loader = val;
        },
        showRowDetails (event, { item }) {
            this.handleView(item);
        },
        changePage (event) {
            this.pagination.page = event;
            this.getLocationsPerSite();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.getLocationsPerSite();
        },
        sort (event) {
            switch (this.sortable[event]) {
            case null:
                this.sortable[event] = 'desc';
                break;
            case 'asc':
                this.sortable[event] = 'desc';
                break;
            case 'desc':
                this.sortable[event] = 'asc';
                break;
            }

            this.sortingColumn = {
                name: event,
                value: this.sortable[event]
            };
        },
        getLocationsPerSite () {
            const params = {
                'filter[place_id]': this.activeSite.id,
                'stats[total]': 'count',
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount
            };

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }
            this.loading = true;
            return HttpService.get(getEndpoint('locations'), {
                params: params
            }).then((resp) => {
                const result = resp.data.data;
                this.$store.commit('customers/SET_LOCATIONS_PER_SITE', result);
                this.$store.commit('customers/SET_UPDATE', false);
                this.pagination.amountAll = resp.data.meta.stats.total.count;
                this.loading = false;
            });
        }
    },
    created () {
        this.getLocationsPerSite();
    }
};
</script>

<style scoped>

</style>
