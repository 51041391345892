<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-icon :size="size"
                    v-bind="attrs"
                    data-test="icon"
                    :color="color"
                    :class="className"
                    @click="onClick"
                    :disabled="disabled"
                    v-on="on">{{ icon }}</v-icon>
        </template>
        <span>{{ tooltip }}</span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'HintingIcon',
    props: {
        size: {
            type: [String, Number],
            'default': 20
        },
        icon: {
            type: String,
            required: true
        },
        tooltip: {
            type: String,
            required: true
        },
        color: {
            type: String,
            required: false
        },
        className: {
            type: [String, Array, Object],
            required: false
        },
        disabled: {
            type: Boolean,
            'default': false
        },
        stop: {
            type: Boolean,
            'default': false
        }
    },
    methods: {
        onClick ($event) {
            this.stop && $event.stopPropagation();
            this.$emit('click');
        }
    }
};
</script>

<style scoped>

</style>
