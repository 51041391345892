<template>
    <v-col cols="12" class="chips-wrapper mb-3">
        <v-row class="nowrap">
            <v-col class="py-0">
                <FiltersChips :presets="filterPresets" clickable :reset-filter="resetFilter" />
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import { ICONS } from '@/constants/icons';
import FiltersChips from '@/components/widgets/tables/FilterPresets/FiltersChips.vue';
import { mapState } from 'vuex';

export default {
    name: 'ActiveFiltersBar',
    components: { FiltersChips },
    data: () => ({
        ICONS
    }),
    props: {
        resetFilter: {
            type: Function
        }
    },
    computed: {
        ...mapState('filters', [
            'filters'
        ]),
        ...mapState('filterPresets', [
            'presets',
            'visiblePresets'
        ]),
        filterPresets () {
            return this.presets.filter(preset => this.visiblePresets.includes(preset.id));
        }
    }
};
</script>
