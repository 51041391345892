var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{staticClass:"mb-8",attrs:{"align-center":"","justify-space-between":""}},[_c('v-flex',[_c('a',{staticClass:"mr-3",on:{"click":_vm.emitFetch}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.ICONS.REFRESH))]),_vm._v(" "+_vm._s(_vm.$t('buttons.refresh_list'))+" ")],1),_c('GetFile',{attrs:{"title":_vm.$t('buttons.export_xls'),"loader":_vm.xlsLoader,"data-test":"getXls"},on:{"get-file":_vm.emitGetFile}},[_c('template',{slot:"icon"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.ICONS.EXPORT)+" ")])],1)],2)],1)],1),_c('Pagination',{attrs:{"pagination":_vm.pagination},on:{"change-page":function($event){return _vm.changePage($event)},"change-amount":function($event){return _vm.changeAmount($event)}}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.getHeaders(),"hide-default-header":_vm.$vuetify.breakpoint.smAndUp,"disable-sort":"","loading":_vm.loader,"items":_vm.inspections,"item-class":_vm.additionalClass,"disable-pagination":"","hide-default-footer":"","show-select":"","item-key":"id","height":"60vh","fixed-header":""},on:{"dblclick:row":_vm.showRowDetails},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.smAndUp)?{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('thead',[_c('tr',_vm._l((props.headers),function(headerItem,index){return _c('th',{key:index},[(headerItem.value === 'data-table-select')?_c('v-simple-checkbox',{attrs:{"ripple":false,"indeterminate":props.someItems && !props.everyItem,"color":"purple"},on:{"input":on['toggle-select-all']},model:{value:(props.everyItem),callback:function ($$v) {_vm.$set(props, "everyItem", $$v)},expression:"props.everyItem"}}):_c('SortableHeader',{attrs:{"header-item":headerItem},on:{"sort":_vm.sort}})],1)}),0)])]}}:null,{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.showDetails(item.id)}}},[_vm._v(_vm._s(item.id))])]}},{key:"item.parsed_local_end_date_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInspectionDate(item))+" ")]}},{key:"item.template_name",fn:function(ref){
var item = ref.item;
return [(item.template_color)?_c('span',{staticClass:"color-example",style:(_vm.getCssVars(item.template_color))}):_vm._e(),_vm._v(" "+_vm._s(item.template_name)+" ")]}},{key:"item.state_mark",fn:function(ref){
var item = ref.item;
return [(_vm.stateIcon(item))?_c('HintingIcon',{attrs:{"color":_vm.stateIconColor(item),"icon":_vm.stateIcon(item),"tooltip":_vm.stateTooltip(item)}}):_vm._e()]}},{key:"item.place_mark",fn:function(ref){
var item = ref.item;
return [(_vm.placeIcon(item))?_c('HintingIcon',{attrs:{"color":_vm.placeIconColor(item),"icon":_vm.placeIcon(item),"tooltip":_vm.placeTooltip(item)}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.isInspectionExpired(item))?_c('HintingIcon',{attrs:{"className":"mr-2","icon":_vm.ICONS.DETAILS,"data-test":"showDetails","tooltip":_vm.$t('actions.view')},on:{"click":function($event){return _vm.showDetails(item.id)}}}):_vm._e(),(_vm.canRemoveInspection)?_c('ButtonWithConfirmation',{attrs:{"item-id":item.id,"data-test":"removeInspection","callback":_vm.deleteInspection}}):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.showGroupActions)?_c('div',{staticClass:"mt-2"},[(_vm.canRemoveInspection)?_c('ButtonWithConfirmation',{attrs:{"item-id":_vm.selected,"data-test":"removeManyInspections","callback":_vm.deleteManyInspections}},[[_c('v-btn',{staticClass:"mr-3",attrs:{"text":""}},[_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v(_vm._s(_vm.ICONS.DELETE))]),_vm._v(" "+_vm._s(_vm.$t('buttons.remove_selected'))+" ")],1)]],2):_vm._e()],1):_vm._e(),(_vm.activeInspection)?_c('InspectionDetails',{attrs:{"dialog":_vm.detailsVisibility},on:{"visibility":function($event){_vm.detailsVisibility = $event},"close":_vm.closeDetailsDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }