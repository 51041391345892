import { i18n } from '@/plugins/i18n';
import { FILTERS_V2 } from '@/constants/filtersModelNames';

export const mapFiltersForPreset = (filters, forEdit = false) => {
    const mappedFilter = {};

    const nonFilterKeys = [FILTERS_V2.START_DATE, FILTERS_V2.END_DATE]; // currently we don't want to use dates in presets

    const activeFilters = forEdit
        ? Object.entries(filters).filter(([key, _]) => !nonFilterKeys.includes(key))
        : Object.entries(filters).filter(([key, value]) => !nonFilterKeys.includes(key) && isFilterActive(value));

    activeFilters.map(([key, value]) => {
        if (Array.isArray(value)) {
            mappedFilter[key] = value.map((item) => item.value);
        } else if (value !== null && typeof value === 'object') {
            mappedFilter[key] = value.value;
        } else {
            mappedFilter[key] = value;
        }
    });

    return mappedFilter;
};

export const mapFiltersFromPreset = (preset) => {
    if (!preset) return {};

    const filters = {};

    const nonFilterKeys = ['id', 'name', FILTERS_V2.START_DATE, FILTERS_V2.END_DATE]; // while currently dates are set as today, we don't want to use them as filters
    const activeFilters = Object.keys(preset).filter(filter => !nonFilterKeys.includes(filter) && isFilterActive(preset[filter]));

    activeFilters.forEach(filter => {
        if (Array.isArray(preset[filter])) {
            filters[filter] = preset[filter].map(filter => ({ text: filter.name, value: filter.id }));
        } else if (typeof preset[filter] === 'boolean') {
            filters[filter] = { text: preset[filter] ? i18n.t('answers.yes') : i18n.t('answers.no'), value: preset[filter] };
        } else {
            filters[filter] = { text: preset[filter], value: preset[filter] };
        }
    });

    return filters;
};

export const applyPresetToFilters = (filters, preset) => {
    const filtersObject = Object.assign({}, filters);

    const mappedFilters = mapFiltersFromPreset(preset);
    Object.keys(mappedFilters).forEach(key => {
        if (!filtersObject[key]) {
            filtersObject[key] = [];
        }

        if (key === FILTERS_V2.IS_COMPLETED) {
            let isCompletedValue = null;

            const filterValue = filtersObject[key]?.value;
            const mappedFilterValue = mappedFilters[key]?.value;

            if (filterValue !== undefined && filterValue !== null) {
                isCompletedValue = filterValue.toString() === mappedFilterValue.toString() ? filterValue.toString() : null;
            } else {
                isCompletedValue = mappedFilterValue.toString();
            }

            filtersObject[key] = isCompletedValue;
        } else {
            filtersObject[key] = [...filtersObject[key], ...mappedFilters[key]];
        }
    });

    return filtersObject;
};

export const isFilterActive = (filter) => {
    if (Array.isArray(filter)) {
        return filter.length > 0;
    } else {
        return filter !== null && filter !== undefined;
    }
};
