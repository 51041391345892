<template>
    <v-row class="mb-3" :justify="justify">
        <v-col
            :lg="!alwaysMinified && 3"
            :md="!alwaysMinified && 4"
            cols="12"
            :class="{ 'pb-0': alwaysMinified || $vuetify.breakpoint.smAndDown }">
           <Search v-if="withSearch" @search="handleSearch($event)"></Search>
        </v-col>
        <v-col
           :lg="!alwaysMinified && defined ? 9 : 8"
            :offset-lg="!alwaysMinified && !defined ? 1 : 0"
            :md="!alwaysMinified && defined ? 8 : 7"
            :offset-md="!alwaysMinified && !defined ? 1 : 0"
            cols="12"
            :class="{ 'pt-0': alwaysMinified || $vuetify.breakpoint.smAndDown }"
        >
            <Filters
                :defined="defined"
                ref="filter"
                :alwaysMinified="alwaysMinified"
                :minWidth="minWidth"
                v-if="withFilters"
                :is-modal="isModal"
                @reset="emitReset"
            />
        </v-col>
        <ActiveFiltersBar v-if="defined && hasVisiblePresets" />
    </v-row>
</template>

<script>
import Search from '@/components/widgets/tables/Search';
import Filters from '@/components/widgets/tables/Filters';
import { mapState } from 'vuex';
import ActiveFiltersBar from '@/components/widgets/tables/FilterPresets/ActiveFiltersBar.vue';

export default {
    name: 'FiltersBar',
    components: { ActiveFiltersBar, Search, Filters },
    props: {
        withSearch: {
            type: Boolean,
            'default': true
        },
        withFilters: {
            type: Boolean,
            'default': true
        },
        isModal: {
            type: Boolean,
            'default': false
        },
        alwaysMinified: {
            type: Boolean,
            'default': false
        },
        minWidth: {
            type: Number,
            'default': 1
        },
        defined: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        ...mapState('filters', [
            'filters'
        ]),
        ...mapState('filterPresets', [
            'visiblePresets',
            'activePreset'
        ]),
        justify () {
            return (this.withSearch && this.withFilters) ? 'space-between' : 'end';
        },
        hasVisiblePresets () {
            return this.visiblePresets.length > 0;
        }
    },
    methods: {
        handleSearch (event) {
            this.$emit('search', event);
        },
        resetSpecifyFilter (prop) {
            this.$refs.filter.resetSpecifyFilter(prop);
        },
        setSpecifyFilter (prop, val) {
            this.$refs.filter.setSpecifyFilter(prop, val);
        },
        clearFilters () {
            this.$refs.filter.clearFilters();
        },
        emitReset () {
            this.$emit('reset');
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/_variables.scss";
@import "@/assets/css/_mixins.scss";
.chip-value {
    margin-left: 6px;
}
.chips-wrapper {
    background: #fafafa;
    border: 1px solid #dbdbdb;
    border-left: none;
    border-right: none;
    margin-top: 12px;
}
.chips-save {
    width: 88px;
}
.chips {
    width: calc(100% - 88px);
}
::v-deep {
    & .v-slide-group__content {
        padding: 0;
        margin: -2px 0;
    }
    & .v-slide-group:not(.v-slide-group--has-affixes) .v-slide-group__content {
        @media #{map-get($display-breakpoints, 'lg-and-up')} {
            transform: translateY(0) !important;
        }
    }
}
</style>
