import { FILTERS_V2 } from '@/constants/filtersModelNames';

export default {
    computed: {
        localConfig () {
            return this.modalFiltersConfig || this.filtersConfig;
        },
        filterPropertyName () {
            return this.isModal ? 'modalFilters' : 'filters';
        },
        filterSearchPropertyName () {
            return this.isModal ? 'modalFiltersSearching' : 'filtersSearching';
        }
    },
    watch: {
        // workaround for vuetify
        '$i18n.locale': {
            handler (val, oldVal) {
                if (val !== oldVal) {
                    this.$nextTick(() => {
                        this.refreshConfig();
                    });
                }
            }
        }
    },
    methods: {
        setFiltersWatcher (callback, withPresetClearer = false) {
            const presetSafeFilters = [FILTERS_V2.START_DATE, FILTERS_V2.END_DATE];

            this.localConfig.forEach(filter => {
                const el = filter.model;
                this.$watch(`${this.filterPropertyName}.${el}`, function (newVal) {
                    if (newVal) {
                        if (withPresetClearer && !presetSafeFilters.includes(el) && this.$store.state.filterPresets.activePreset) {
                            this.$store.commit('filterPresets/SET_ACTIVE_PRESET', null);
                        }
                        if (this.pagination) {
                            this.pagination.page = 1;
                        }
                        callback();
                        if (filter.hasProperty('referralCallback')) {
                            filter.referralCallback(newVal);
                        }

                        // if (filter.hasProperty('searchCallback')) {
                        //     filter.searchCallback();
                        // }
                    } else if (newVal === null) {
                        callback();
                    }
                });
            });
        },
        setPresetWatcher (callback) {
            this.$watch('activePreset', function (newVal) {
                if (newVal) {
                    callback();
                } else if (newVal === null) {
                    callback();
                }
            });
        },
        refreshConfig () {
            const data = [...this.localConfig];
            data.forEach(el => {
                if (el.hasProperty('loading')) {
                    el.loading = false;
                }
            });

            this.$store.commit('filters/SET_CONFIG', data);
        }
    },
    mounted () {
        this.localConfig.forEach(el => {
            if (el.hasProperty('search')) {
                this.$watch(`${this.filterSearchPropertyName}.${el.search}`, function (newVal, oldVal) {
                    if (oldVal !== undefined && newVal !== null) {
                        const searchVal = newVal || null;
                        if (el.hasProperty('searchOptions')) {
                            const params = [searchVal];
                            Object.keys(el.searchOptions).forEach(k => {
                                if (k === 'reference') {
                                    const data = this[this.filterPropertyName]?.[el.searchOptions[k]]?.join(',') || null;
                                    params.push(data);
                                } else {
                                    params.push(el.searchOptions[k]);
                                }
                            });
                            el.searchCallback(...params);
                        } else {
                            el.searchCallback(searchVal);
                        }
                    }
                });
            }
        });
    }
};
