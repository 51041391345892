<template xmlns:v-slot="https://www.w3.org/1999/XSL/Transform">
    <div class="bar">
        <v-layout justify-space-between
                  align-center
                  :style="{'height': navbarHeight}">
            <v-flex :style="firstColumnStyles"
                    :class="{'light-background': isLogged}">
                <v-layout align-center
                          justify-center
                          v-if="isLogged">
                    <Logo custom-logo
                          :contain="true"
                          :max-width="logoWidth"
                          width="auto"
                          :height="logoHeight"></Logo>
                </v-layout>
            </v-flex>

            <v-flex class="pl-3"
                    v-if="isLogged">
                <v-layout align-center>
                    <v-flex md4>
                        <QRCode class="mr-4"
                                :url="configCode"
                                :handler="getQRCode"></QRCode>
                    </v-flex>
                    <v-flex md4 v-if="!isMobile">
                        <v-layout justify-center>
                            <Logo light
                                  :width="logoWidth"
                                  :height="logoHeight"></Logo>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex class="grow--0">
                <v-layout align-center justify-end class="pr-3">
                    <v-menu bottom
                            offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn text
                                   title
                                   v-bind="attrs"
                                   v-on="on"
                                   color="white"
                                   class="mr-3 flag-btn">
                                <v-img :src="flagUrl" class="flag mr-3"></v-img>
                                {{ buttonTitle }}</v-btn>
                        </template>
                        <v-list class="mb-0">
                            <v-list-item v-for="(lang, i) in locales"
                                         @click="switchLocale(lang)"
                                         :key="i">{{ lang }}</v-list-item>
                        </v-list>
                    </v-menu>
                    <div class="pl-3 bordered"
                         v-if="isLogged">
                        <div v-if="!isMobile">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <h6 v-bind="attrs"
                                        v-on="on"
                                        class="text--m font-weight-medium white--text">{{ userName }}</h6>
                                </template>

                                <v-list class="mb-0">
                                    <v-list-item>
                                        <Logout></Logout>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <v-icon v-else class="secondary--text" @click="openMenu">{{ ICONS.MENU }}</v-icon>
                    </div>
                </v-layout>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { regularHeight, regularWidth, mobileHeight, mobileWidth, miniWidth, miniHeight } from '@/constants/logo';
import { fullNavbarWidth, miniNavbarWidth, navbarHeight } from '@/constants/navbar';
import Logo from '@/components/widgets/Logo';
import Logout from '@/components/auth/Logout';
import QRCode from '@/components/widgets/dashboard/QRCode';
import { ICONS } from '@/constants/icons';

export default {
    name: 'TopBar',
    components: { Logo, Logout, QRCode },
    data: () => ({
        regularHeight,
        regularWidth,
        mobileHeight,
        mobileWidth,
        fullNavbarWidth,
        miniNavbarWidth,
        navbarHeight,
        miniHeight,
        miniWidth,
        buttonTitle: 'pl',
        ICONS
    }),
    computed: {
        ...mapState('locale', [
            'locales',
            'currentLocale'
        ]),
        ...mapState('authorization', [
            'isLogged',
            'userName'
        ]),
        ...mapState('qrcodes', [
            'configCode'
        ]),
        ...mapFields('navigation', [
            'isMenuOpened'
        ]),
        firstColumnStyles () {
            const _width = this.isMobile ? '130px' : this.fullNavbarWidth;
            return {
                width: _width,
                flex: `1 1 ${_width}`,
                'max-width': _width
            };
        },
        logoWidth () {
            return this.isMobile ? this.mobileWidth : this.regularWidth;
        },
        logoHeight () {
            return this.isMobile ? this.mobileHeight : this.regularHeight;
        },
        flagUrl () {
            return require(`@/assets/images/${this.buttonTitle}.svg`);
        },
        isMobile () {
            return this.$vuetify.breakpoint.smAndDown;
        }
    },
    methods: {
        async switchLocale (lang) {
            this.buttonTitle = lang;
            this.$vuetify.lang.current = lang;
            await this.$store.dispatch('locale/getLocaleMessages', lang);
        },
        openMenu () {
            this.isMenuOpened = true;
        },
        getRoute (item) {
            return Object.prototype.hasOwnProperty.call(item, 'route') ? { name: item.route } : { path: item.path };
        },
        getQRCode () {
            return this.$store.dispatch('qrcodes/getConfigCode');
        }
    },
    beforeCreate () {
        this.$store.dispatch('authorization/checkUserName');
    },
    created () {
        this.buttonTitle = this.currentLocale;
    }
};
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";

    .bar {
        width: 100%;
        background-color: $primary;
        border-bottom: 1px solid $primary;
    }

    .bordered {
        border-left: 1px solid #fff;
    }

    .flag {
        height: 15px;
        width: 25px;
        opacity: 0.8;
    }

    .light-background {
        background-color: #fff;
        border-bottom: 1px solid $light_grey_2;
    }

    .flag-btn {
        text-transform: uppercase;
    }
</style>
