<template>
    <Modal :modal-title="$t('defined_filters.save_preset_modal_title')"
           :dialog="dialog"
           :loading="loading"
           max-width="800px"
           @close="close">
        <template slot="content">
            <v-row class="modal-body">
                <v-col>
                    <TextField
                        :label="$t('defined_filters.preset_name')"
                        :rules="`max:50|required`"
                        counter="50"
                        v-model="name"
                    />
                </v-col>
                <v-col cols="auto">
                    <v-btn
                        color="secondary"
                        :loading="loading"
                        :disabled="loading || !this.name.length"
                        @click="save"
                    >
                        {{ $t('buttons.save') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/templates/Modal';
import TextField from '@/components/widgets/forms/TextField.vue';
import { mapFiltersForPreset } from '@/components/widgets/tables/FilterPresets/utils';
import { mapState } from 'vuex';

export default {
    name: 'SavePresetModal',
    components: { TextField, Modal },
    data: () => ({
        name: '',
        loading: false
    }),
    props: {
        dialog: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapState('filters', [
            'filters'
        ])
    },
    methods: {
        close () {
            this.$emit('close');
        },
        save () {
            this.loading = true;

            return this.$store.dispatch('filterPresets/addPreset', {
                name: this.name,
                ...mapFiltersForPreset(this.filters)
            }).then(() => {
                this.loading = false;
                this.close();
            });
        }
    },
    watch: {
        dialog (value) {
            if (!value) {
                this.name = '';
            }
        }
    }
};
</script>

<style scoped>
    .modal-body {
        margin-top: -12px;
        margin-bottom: -12px;
    }
</style>
