<template>
    <div class="d-contents">
        <v-row
            align-center
            :wrap="$vuetify.breakpoint.xsOnly"
            no-gutters
            justify-space-between
            class="flex-grow-0 mb-3"
            :class="{'nowrap': $vuetify.breakpoint.mdAndUp, 'px-3': $vuetify.breakpoint.smAndDown}">
            <v-col class="align-self-end">
                <a class="mr-3"
                   @click="emitFetch">
                    <v-icon size="20">{{ ICONS.REFRESH }}</v-icon>
                    {{ $t('buttons.refresh_list') }}
                </a>
            </v-col>
            <v-col>
                <v-layout justify-end>
                    <v-btn color="secondary"
                           data-test="addNewEmployeeBtn"
                           v-if="canAddTrack"
                           @click="openTrackConfiguration()">
                        <v-icon left>{{ICONS.ADD}}</v-icon>
                        {{ $t('tracks.add_title') }}
                    </v-btn>
                </v-layout>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <p class="mb-0 text-left text--xs">
                    {{$t('labels.all_records')}}: <strong>{{ pagination.amountAll || 0 }}</strong>
                </p>
            </v-col>
        </v-row>

        <v-data-table
            :headers="headers"
            :hide-default-header="$vuetify.breakpoint.smAndUp"
            disable-sort
            :loading="loader"
            :items="tracks"
            disable-pagination
            hide-default-footer
            show-select
            height="100%"
            v-model="selected"
            @dblclick:row="showTrackDetails"
            class="elevation-2 table-wrapper">
            <template v-slot:header="{ props, on }"
                      v-if="$vuetify.breakpoint.smAndUp">
                <thead>
                <tr>
                    <th v-for="(headerItem, index) in props.headers"
                        :key="index">
                        <v-simple-checkbox
                            :ripple="false"
                            @input="on['toggle-select-all']"
                            v-if="headerItem.value === 'data-table-select'"
                            v-model="props.everyItem"
                            :indeterminate="props.someItems && !props.everyItem"
                            color="purple"
                        ></v-simple-checkbox>
                        <SortableHeader v-else :header-item="headerItem"
                                        @sort="sort"></SortableHeader>
                    </th>
                </tr>
                </thead>
            </template>
            <template v-slot:item.name="{ item }">
                <span class="clickable"
                      @click="showDetailsDialog(item)">{{ item.name }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-row align="center"
                           justify="center">
                    <HintingIcon
                        class-name="mr-2"
                        :icon="ICONS.DETAILS"
                        data-test="showDetailsBtn"
                        @click="showDetailsDialog(item)"
                        :tooltip="$t('actions.view')"></HintingIcon>
                    <HintingIcon
                        class-name="mr-2"
                        v-if="canEditTrack"
                        :icon="ICONS.EDIT"
                        @click="openTrackConfiguration(item)"
                        data-test="editTrackBtn"
                        :tooltip="$t('actions.edit')"></HintingIcon>
                    <ButtonWithConfirmation
                        v-if="canDeleteTrack"
                        :item-id="item.id"
                        :callback="deleteTrack"></ButtonWithConfirmation>
                </v-row>
            </template>

        </v-data-table>

        <div v-if="showGroupActions"
             class="flex-grow-0 mt-6">
            <ButtonWithConfirmation :item-id="selected"
                                    v-if="canDeleteTrack"
                                    :callback="deleteManyTracks">
                <template>
                    <v-btn class="mr-3" text>
                        <v-icon left size="20">{{ ICONS.DELETE }}</v-icon>
                        {{ $t('buttons.remove_selected') }}
                    </v-btn>
                </template>
            </ButtonWithConfirmation>
        </div>

        <TrackDetails :dialog="showDetails"
             @visibility="showDetails = $event"
             @close="closeDetailsDialog"
             v-if="activeTrack"></TrackDetails>

    </div>
</template>

<script>
import { ICONS } from '@/constants/icons';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import HintingIcon from '@/components/widgets/HintingIcon';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';

import { mapGetters, mapState } from 'vuex';
import paginationSettings from '@/mixins/paginationSettings';
import TrackDetails from '@/components/cyclicRevisions/TrackDetails.vue';

export default {
    name: 'TracksTable',
    components: {
        TrackDetails,
        SortableHeader,
        HintingIcon,
        ButtonWithConfirmation
    },
    mixins: [paginationSettings],
    props: {
        loader: {
            type: Boolean,
            'default': false
        }
    },
    data: (vm) => ({
        pagination: vm.setupPagination(),
        ICONS,
        sendMailVisibility: false,
        selected: [],
        sortable: {
            name: 'desc',
            client_name: 'asc',
            location_name: 'asc',
            point_name: 'asc',
            assigned_employee_full_name: 'asc',
            start_time: 'asc',
            end_time: 'asc',
            start_date: 'asc',
            end_date: 'asc'
        },
        showDetails: false
    }),
    computed: {
        ...mapState('cyclicRevisions', [
            'tracks',
            'tracksTotalAmount'
        ]),
        ...mapGetters('manageTrack', [
            'activeTrack'
        ]),
        headers () {
            return [
                {
                    text: this.$t('tracks.track_table.track_name'),
                    value: 'name',
                    sortable: true
                },
                {
                    text: this.$t('tracks.track_table.client'),
                    value: 'client_name',
                    sortable: true
                },
                {
                    text: this.$t('tracks.track_table.object'),
                    value: 'location_name',
                    sortable: true
                },
                {
                    text: this.$t('tracks.track_table.location'),
                    value: 'point_name',
                    sortable: true
                },
                {
                    text: this.$t('tracks.track_table.assigned_employee'),
                    value: 'assigned_employee_full_name',
                    sortable: true
                },
                {
                    text: this.$t('tracks.track_table.activity_time_start'),
                    value: 'start_time',
                    sortable: true
                },
                {
                    text: this.$t('tracks.track_table.activity_time_end'),
                    value: 'end_time',
                    sortable: true
                },
                {
                    text: this.$t('tracks.track_table.start_datetime'),
                    value: 'start_date',
                    sortable: true
                },
                {
                    text: this.$t('tracks.track_table.end_datetime'),
                    value: 'end_date',
                    sortable: true
                },
                {
                    text: this.$t('labels.actions'),
                    value: 'actions',
                    icon: false,
                    width: '200px',
                    sortable: false
                }
            ];
        },
        canEditTrack () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.TRACKS);
        },
        canDeleteTrack () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.TRACKS);
        },
        canAddTrack () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.TRACKS);
        },
        showGroupActions () {
            return this.selected.length > 0;
        }
    },
    methods: {
        emitFetch () {
            this.$emit('fetch');
        },
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        sort (event) {
            const val = event;
            switch (this.sortable[val]) {
            case null:
                this.sortable[val] = 'asc';
                break;
            case 'asc':
                this.sortable[val] = 'desc';
                break;
            case 'desc':
                this.sortable[val] = 'asc';
                break;
            }

            this.$emit('sort', {
                name: val,
                value: this.sortable[val]
            });
        },
        openTrackConfiguration (item = null) {
            this.$store.commit('manageTrack/RESET_TRACK_DATA');
            item ? this.$router.push({ name: 'editTrack', params: { id: item.id } }) : this.$router.push({ name: 'trackManagement' });
        },
        showTrackDetails (event, { item }) {
            this.showDetailsDialog(item);
        },
        showDetailsDialog (item) {
            this.$store.dispatch('manageTrack/getActiveTrack', item.id)
                .then(() => {
                    this.showDetails = true;
                });
        },
        closeDetailsDialog () {
            this.$store.commit('manageTrack/RESET_TRACK_DATA');
            this.showDetails = false;
        },
        deleteTrack (id) {
            return this.$store.dispatch('cyclicRevisions/deleteTrack', id).then(() => {
                this.emitFetch();
            });
        },
        deleteManyTracks (arrId) {
            const ids = arrId.map(el => parseInt(el.id)).flat();

            return this.$store.dispatch('cyclicRevisions/deleteManyTracks', ids)
                .then(() => {
                    this.selected = [];
                    this.emitFetch();
                });
        }
    },
    watch: {
        tracksTotalAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        }
    }
};
</script>

<style scoped>
.table-wrapper {
    overflow: hidden;
}
</style>
