<template>
    <Modal :modal-title="$t('defined_filters.edit_preset_modal_title')"
           :dialog="dialog"
           max-width="800px"
           @close="close">
        <template slot="content">
            <v-row class="modal-body">
                <v-col cols="12">
                    <h3 class="mb-3">{{$t('defined_filters.preset_filters_header')}}</h3>
                    <FiltersChips :filters="filters" column :reset-filter="filtersCount > 1 ? removeFilter : null" />
                </v-col>
                <v-col>
                    <h3 class="mb-3">{{$t('defined_filters.preset_name_header')}}</h3>
                    <TextField
                        :label="$t('defined_filters.preset_name')"
                        :rules="`max:50|required`"
                        counter="50"
                        v-model="name"
                    />
                </v-col>
            </v-row>
        </template>
        <template slot="footer">
            <v-btn color="secondary"
                   outlined
                   @click="close">{{ $t('buttons.cancel') }}</v-btn>
            <v-btn color="secondary"
                   :loading="isLoading"
                   :disabled="isLoading || !this.name.length || !filtersCount"
                   data-test="saveBtn"
                   @click="update">{{ $t('buttons.save') }}</v-btn>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/templates/Modal';
import TextField from '@/components/widgets/forms/TextField.vue';
import { mapFiltersForPreset, mapFiltersFromPreset } from '@/components/widgets/tables/FilterPresets/utils';
import FiltersChips from '@/components/widgets/tables/FilterPresets/FiltersChips.vue';
import { FILTERS_V2 } from '@/constants/filtersModelNames';

export default {
    name: 'UpdatePresetModal',
    components: { FiltersChips, TextField, Modal },
    data: (vm) => ({
        name: vm.preset.name,
        filters: mapFiltersFromPreset(vm.preset),
        isLoading: false
    }),
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        preset: {
            type: Object,
            required: true
        }
    },
    computed: {
        filtersCount () {
            return Object.keys(mapFiltersForPreset(this.filters)).length;
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        update () {
            this.isLoading = true;

            return this.$store.dispatch('filterPresets/updatePreset', {
                ...mapFiltersForPreset(this.filters, true),
                name: this.name,
                id: this.preset.id
            }).then(() => {
                this.isLoading = false;
                this.close();
            });
        },
        removeFilter (filter) {
            this.filters = { ...this.filters, [filter]: filter === FILTERS_V2.IS_COMPLETED ? null : [] };
        }
    },
    watch: {
        dialog (val) {
            if (val) {
                this.filters = mapFiltersFromPreset(this.preset);
                this.name = this.preset.name;
            }
        }
    }
};
</script>

<style scoped>
    .modal-body {
        margin-top: -12px;
        margin-bottom: -12px;
    }
</style>
