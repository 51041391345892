export const FILTERS = {
    TERRITORY_ID: 'territory_id',
    CITY_ID: 'city_id',
    SYSTEM_LOG_TYPE_ID: 'code',
    SITE_GROUP_ID: 'place_group_id',
    PERSON_ID: 'employee_id',
    PERSON_ASSIGNED_ID: 'responsible_employee_id',
    SITE_ID: 'place_id',
    START_DATE: 'start_date_time',
    CREATED_AT: 'created_at',
    END_DATE: 'end_date_time',
    OCC_START_DATE: 'occurrence_dateS',
    OCC_END_DATE: 'occurrence_dateE',
    INCIDENT_STATUS_ID: 'incident_status_id',
    INCIDENT_TYPE_ID: 'incident_kind_id',
    INSPECTOR_ID: 'inspector_id',
    CUSTOMER_ID: 'customer_id',
    LOCATION_ID: 'point_id',
    INSPECTION_END_DATE: 'inspection_end_datetime',
    TEMPLATE_ID: 'template_id',
    START_TEMPLATE_ID: 'start_template_id',
    END_TEMPLATE_ID: 'end_template_id',
    TEMPLATE_QUESTIONS_ID: 'template_question_id',
    INSPECTION_STATE: 'inspections_state',
    CHOSEN_YEAR: 'year',
    COMPANY_ID: 'company_id',
    COMPANY_TYPE_ID: 'company_type_id',
    EMPLOYEE_GROUP_ID: 'employee_group_id',
    POSITION_ID: 'position_id',
    COUNTRY_ID: 'country_id',
    CHOSEN_MONTH: 'month',
    COMPLITION_RATE: 'complition_rate',
    CHOSEN_WEEK: 'week'
};

export const FILTERS_V2 = {
    SITE_ID: 'location',
    PERSON_ID: 'employee',
    PERSON_ASSIGNED_ID: 'assigned_employee',
    INCIDENT_TYPE_ID: 'incident_type',
    INCIDENT_STATUS_ID: 'status',
    START_DATE: 'start_date',
    END_DATE: 'end_date',
    CLIENT: 'client',
    POINT: 'point',
    LOCATION: 'location',
    IS_COMPLETED: 'is_completed'
};

export const FILTERS_SEARCHING = {
    SITE: 'searchSites',
    SITE_PER_CUSTOMER: 'searchSitesPerCustomer',
    EMPLOYEES: 'searchEmployees',
    PERSON_ASSIGNED: 'searchPersonAssigned',
    CITY: 'searchCities',
    TERRITORY: 'searchTerritories',
    INSPECTOR: 'searchInspectors',
    CUSTOMER: 'searchCustomers',
    LOCATION: 'searchPoints',
    LOCATION_PER_SITE: 'searchPointsPerSite',
    TEMPLATE: 'searchTemplates',
    START_TEMPLATE: 'startSearchTemplates',
    END_TEMPLATE: 'endSearchTemplates',
    TEMPLATE_QUESTION: 'searchTemplateQuestions',
    INCIDENT_TYPE: 'searchTypes',
    INCIDENT_STATUS: 'searchStatus',
    EMPLOYEES_GROUPS_NAME: 'searchEmployeesGroups',
    COMPANIES: 'searchCompanies',
    COMPANY_TYPES: 'searchCompanyTypes',
    POSITIONS: 'searchPositions',
    COUNTRY: 'searchCountries'
};

export const INSPECTION_STATES = {
    1: {
        filter: 'correct',
        value: true
    },
    2: {
        filter: 'correct',
        value: false
    },
    3: {
        filter: 'correct_gps',
        value: false
    },
    4: {
        filter: 'missed_done_date',
        value: true
    },
    5: {
        filter: 'done',
        value: false
    },
    6: {
        filter: 'planned',
        value: true
    }
};
